<template>
  <main class="sub">
    <div class="inner job-posting">
      <div class="tit req-type">
        <h1>구매 내역</h1>
        <p class="stit">호구153에서 구매한 내역들을 확인하실 수 있습니다.</p>
      </div>
      <div class="sub-cont">
        <div class="myp-buy-wrap">
          <div class="search-item-wrap">
            <div class="label">구매일자</div>
            <div class="date-item-wrap">
              <input
                type="text"
                id="startDt"
                :value="startDt"
                ref="startDt"
                class="inp datepicker"
              />
              <span class="e01">-</span>
              <input
                type="text"
                id="endDt"
                :value="endDt"
                ref="endDt"
                class="inp datepicker"
              />
            </div>
            <button type="button" @click="search">검색</button>
          </div>
          <table class="tbl01">
            <colgroup>
              <col width="" />
              <col width="250" />
              <col width="" />
            </colgroup>
            <tr>
              <th>구매일자</th>
              <th>내역</th>
              <th>구매금액</th>
            </tr>
            <tr v-for="(purchase, idx) in purchaseList" :key="`pc_${idx}`">
              <td>{{ purchase.regDt }}</td>
              <td>
                <a href="javascript:void(0)">{{ purchase.productName }}</a>
              </td>
              <td>{{ purchase.salesAmt }} 원</td>
            </tr>
            <tr>
              <td colspan="3" v-if="purchaseList.length == 0">
                구매내역이 존재하지 않습니다.
              </td>
            </tr>
            <!--tr>
              <td>2021.07.26</td>
              <td>
                <a href="javascript:void(0)" class="" @click="toggleList(this)"
                  >일자리등록</a
                >
              </td>
              <td>270,000 원</td>
            </tr>
            <tr-- class="result-tr">
              <td colspan="3">
                <div class="result-item-wrap">
                  <ul class="th-list">
                    <li><strong>구매일자</strong></li>
                    <li>2021.07.25</li>
                  </ul>
                  <ul class="td-list">
                    <li>
                      <div class="key">상품명</div>
                      <div class="value">금액</div>
                    </li>
                    <li>
                      <div class="key">룸메이드 클리너 X 3</div>
                      <div class="value"><strong>270,000</strong>원</div>
                    </li>
                    <li>
                      <div class="key">캐셔</div>
                      <div class="value"><strong>270,000</strong>원</div>
                    </li>
                    <li>
                      <div class="key">서비스 이용료</div>
                      <div class="value"><strong>270,000</strong>원</div>
                    </li>
                    <li>
                      <div class="key">시스템 이용료</div>
                      <div class="value"><strong>270,000</strong>원</div>
                    </li>
                    <li>
                      <div class="key">Displ-화면 Blink</div>
                      <div class="value"><strong>270,000</strong>원</div>
                    </li>
                    <li>
                      <div class="key">Displ-Rolling-1시간</div>
                      <div class="value"><strong>270,000</strong>원</div>
                    </li>
                    <li>
                      <div class="key">VAT</div>
                      <div class="value"><strong>270,000</strong>원</div>
                    </li>
                  </ul>
                </div>
              </td>
            </tr-->
            <!--tr>
              <td>2021.07.26</td>
              <td>
                <a href="javascript:void(0)" class="" @click="toggleList(this)"
                  >일자리등록</a
                >
              </td>
              <td>270,000 원</td>
            </tr-->
          </table>
          <!-- 페이징 추가 -->
          <Pagination
            :totalElements="totalElements"
            :currentPageIndex="currentPageIndex"
            :listRowCount="listRowCount"
            :pageLinkCount="pageLinkCount"
            @goPage="onGoPage"
          ></Pagination>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getPurchaseHistoryList } from 'Api/modules'
import { mapGetters } from 'vuex'
import moment from 'moment'
import datePickerMixin from 'Mixins/datePicker'
import Pagination from 'Common/Pagination'
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      startDt: moment().format('YYYY-MM-DD'),
      endDt: moment().format('YYYY-MM-DD'),
      purchaseList: [],
      pageNumber: 0,
      // paging
      totalElements: 0,
      listRowCount: 20,
      pageLinkCount: 5,
      currentPageIndex: 1,
    }
  },
  mixins: [datePickerMixin],
  computed: {
    ...mapGetters(['account']),
  },
  methods: {
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    setStartDate(selectedDate) {
      this.startDt = selectedDate
    },
    setEndDate(selectedDate) {
      this.endDt = selectedDate
    },
    async search() {
      if (!this.startDt || !this.endDt) {
        return false
      }
      const res = await getPurchaseHistoryList({
        startDt: this.startDt,
        endDt: this.endDt,
        pageNumber: this.pageNumber,
      })
      if (res) {
        this.purchaseList = res.content
        this.totalElements = res.totalElements
        this.currentPageIndex = res.pageable.pageNumber + 1
        this.pageNumber = res.pageable.pageNumber
        // todo:: paging ㅊㅓ리
        // this.initUI()
      }
    },
  },
  async created() {
    // todo:: 달력 처리 필요
    await this.search()
    this.initDatePicker('startDt', this.setStartDate)
    this.initDatePicker('endDt', this.setEndDate)
  },
}
</script>

<style></style>
